import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import JoinTheCommunities from "components/Common/JoinTheCommunities";
import SiteData from "components/Common/SiteData";
import SiteImage from "components/Common/SiteImage";
import SiteSection from "components/Common/SiteSection";

import binance from "assets/History/binance.png";
import elonMusk from "assets/History/elon-musk.png";

const History = () => {
  const { history: page } = useSiteData().pages;

  return (
    <Root>
      <div className="header">
        <h1>
          <SiteData path="history.title" />
        </h1>
        <p>{page.intro}</p>
        <img className="binance" src={binance} />
        <img className="elon-musk" src={elonMusk} />
        <div className="fact twitter">
          <label>Use of Twitter</label>
          800 tweets per hour #HODL
        </div>
        <div className="fact google">
          <label>Google searches</label>
          60,000 searches per month
        </div>
      </div>
      <div className="body-width beginning">
        <h2>{page.beginningTitle}</h2>
        <div className="content">
          <SiteImage image={page.imageHodler} />
          <SiteData path="history.beginningContent" />
        </div>
      </div>
      <SiteSection
        path="history.codeSection1"
        image="imageSection1"
        reverse={true}
      />
      <JoinTheCommunities />
      <SiteSection
        path="history.codeSection2"
        image="imageSection2"
        logo={true}
      />
    </Root>
  );
};

export default History;
