import styled from 'styled-components';

import vars from 'variables';

import background from 'assets/History/background.png';
import google from 'assets/History/google.svg';
import twitter from 'assets/History/twitter.svg';

const Root = styled.div`
  .header {
    position: relative;
    background: rgb(17, 20, 47) url(${background}) no-repeat;
  }

  .header h1 {
    color: white;
    text-align: center;
  }

  .header h1 div {
    margin: 0 auto;
  }

  .header p {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .header img {
    position: absolute;
  }

  .header .fact {
    color: ${vars.colors.background};
    position: absolute;
    text-transform: uppercase;
    background: white no-repeat;
    border-radius: 50px;
  }

  .header .fact label {
    color: ${vars.colors.blue};
    display: block;
  }

  .header .twitter {
    background-image: url(${twitter});
  }

  .header .google {
    background-image: url(${google});
  }

  .beginning > h2 {
    text-align: center;
  }

  .content {
    background: rgb(0, 10, 35);
    border: 2px solid rgb(0, 149, 213);
    border-radius: 10px;
  }

  .content h2 {
    font-size: 1.5rem;
  }

  ${vars.desktop.mediaQuery} {
    .header {
      height: 510px;
      margin-top: -120px;
      padding: 122px 0 530px;
      background-position: center bottom -315px;
      background-size: 1640px;
    }

    .header h1 {
      padding-top: 60px;
      padding-bottom: 15px;
      font-size: 5.8rem;
      line-height: 110%;
    }

    .header h1 div {
      width: 1300px;
    }

    .header p {
      font-size: 1.1rem;
    }

    .header img {
      width: 425px;
    }

    .header .binance {
      top: 425px;
      left: calc(50% + 335px);
    }

    .header .elon-musk {
      top: 550px;
      left: 115px;
    }

    .header .fact {
      padding: 20px 30px 20px 80px;
      font-weight: 500;
      line-height: 130%;
      background-position: 23px center;
    }

    .header .fact label {
      font-size: 0.75rem;
      letter-spacing: 1px;
    }

    .header .twitter {
      bottom: 225px;
      left: calc(50% + 320px);
    }

    .header .google {
      bottom: 65px;
      right: 1245px;
    }

    .beginning > h2 {
      margin-top: 120px;
      margin-bottom: 60px;
      font-size: 3.5rem;
    }

    .content {
      padding: 80px 120px 80px 250px;
      position: relative;
    }

    .content img {
      left: 100px;
      position: absolute;
    }

    .content p {
      margin: 40px 0;
      color: ${vars.colors.grey};
    }

    .join-the-communities {
      margin-top: 115px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      margin-top: -100px;
      padding: 122px 0 465px;
      background-position: center bottom -128px;
      background-size: 720px;
    }

    .header h1 {
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 2rem;
      line-height: 110%;
    }

    .header p {
      padding: 0 45px;
      color: ${vars.colors.white};
      font-size: 0.75rem;
      line-height: 145%;
    }

    .header img {
      width: 200px;
    }

    .header .binance {
      display: none;
    }

    .header .elon-musk {
      bottom: 300px;
      left: 15px;
    }

    .header .fact {
      padding: 10px 12px 10px 35px;
      font-size: 0.45rem;
      font-weight: 700;
      line-height: 125%;
      background-position: 10px center;
      background-size: 20px;
    }

    .header .fact label {
      font-size: 0.4rem;
    }

    .header .twitter {
      bottom: 240px;
      right: 10px;
    }

    .header .google {
      bottom: 20px;
      left: 15px;
    }

    .beginning > h2 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 1.2rem;
      font-weight: 600;
    }

    .content {
      margin-bottom: 60px;
      padding: 30px 25px;
    }

    .content img {
      display: none;
    }

    .content h2 {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .content p {
      margin: 15px 0 50px;
    }

    .content h3 {
      font-size: 0.8rem;
      letter-spacing: 1.5px
    }
  }
`;

export { Root };
